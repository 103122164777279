import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Wer kennt das nicht: Ihr definiert eine neue CSS-Regel, kompiliert eure Web-Applikation und werft einen Blick auf
die Seite im Browser. Was? Warum wurde das Styling nicht angewandt?`}</p>
    <p>{`Sehr oft ist der Übeltäter eine andere CSS-Regel mit `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity"
      }}>{`höherer Spezifität`}</a>{`.
Vielleicht hab ihr die Bibliothek eines Drittanbieters eingebunden, deren Styles in Konflikt mit euren stehen.`}</p>
    <p>{`Doch Rettung naht in Form eines neuen CSS-Features: `}<InlineCode mdxType="InlineCode">{`CSS Cascade Layer`}</InlineCode>{`.
Diese ermöglichen uns die Aufteilung unserer Styles in streng getrennte Schichten, um Konflikte zu vermeiden.
Ich zeige euch, wie ihr das neue Feature in einer Angular-Applikation mit `}<a parentName="p" {...{
        "href": "https://sass-lang.com/"
      }}>{`SCSS`}</a>{` anwenden könnt.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAACBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFYe7go0uZ//8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxEE/9oACAEBAAEFAuilnU0MJzDKjM2BFE//xAAWEQEBAQAAAAAAAAAAAAAAAAABEFH/2gAIAQMBAT8BHZ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAAAARARITJRov/aAAgBAQAGPwJI2Y9FpcvR/8QAGhABAQEBAAMAAAAAAAAAAAAAAREAMSFBkf/aAAgBAQABPyEFPF7zTPiMMgKWOF3miuNY63f/2gAMAwEAAgADAAAAECgv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EDI5t//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEn/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUFhgZH/2gAIAQEAAT8QCWrGg0vWOCchQgPeSm0FPr2yYI3admJs54ZgpHY3y5//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Torte mit mehreren erkennbaren Schichten.",
          "title": "Eine Torte mit mehreren erkennbaren Schichten.",
          "src": "/static/1d96df584f63f394c8e19a99d57ea037/e5166/pexels-dalila-dalprat-cake.jpg",
          "srcSet": ["/static/1d96df584f63f394c8e19a99d57ea037/f93b5/pexels-dalila-dalprat-cake.jpg 300w", "/static/1d96df584f63f394c8e19a99d57ea037/b4294/pexels-dalila-dalprat-cake.jpg 600w", "/static/1d96df584f63f394c8e19a99d57ea037/e5166/pexels-dalila-dalprat-cake.jpg 1200w", "/static/1d96df584f63f394c8e19a99d57ea037/b17f8/pexels-dalila-dalprat-cake.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Dalila Dalprat / pexels.com`}</em></p>
    <h2>{`Was sind Cascade Layer?`}</h2>
    <p>{`CSS Cascade Layer geben Autoren mehr Kontrolle über die Kaskade in CSS. Also über die Art und Weise wie
Browser entscheiden, welche Styling-Regeln auf ein HTML-Element angewandt werden.`}</p>
    <p>{`Das neue Feature ist im CSS-Modul `}<a parentName="p" {...{
        "href": "https://drafts.csswg.org/css-cascade-5/#layering"
      }}>{`Cascading and Inheritance Level 5`}</a>{`
definiert. Obwohl das Dokument erst den Entwurf-Status hat, werden Cascade Layer bereits
von `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-cascade-layers"
      }}>{`allen modernen Browsern`}</a>{` unterstützt. Das Dokument besagt:`}</p>
    <blockquote lang="en">
    <strong>Cascade layers</strong> provide a structured way to organize and balance concerns within a single origin. Rules within a single cascade layer cascade together, without interleaving with style rules outside the layer.
    </blockquote>
    <p>{`Das heißt, wir können mehrere Layer definieren – beginnend bei Styles mit niedriger Priorität (z.B. Bibliotheken von
Drittanbietern) bis zu hochprioritären Styles von Komponenten. Konflikte zwischen einzelnen Schichten werden immer so
aufgelöst, dass die CSS-Regeln der Schicht mit höchster Priorität angewandt werden.`}</p>
    <p>{`Wenn wir uns die Schritte in der Kaskade ansehen, dann sehen wir dass die Zugehörigkeit zu einem gewissen Layer geprüft
wird bevor die Spezifität und Reihenfolge der Deklaration geprüft wird.`}</p>
    <ol>
      <li parentName="ol">{`Ursprung und Wichtigkeit (`}<InlineCode mdxType="InlineCode">{`!important`}</InlineCode>{`)`}</li>
      <li parentName="ol">{`Kontext`}</li>
      <li parentName="ol">{`CSS-Regeln im HTML-Element`}</li>
      <li parentName="ol">{`Layer`}</li>
      <li parentName="ol">{`Spezifität`}</li>
      <li parentName="ol">{`Reihenfolge der Deklaration`}</li>
    </ol>
    <p>{`Bitte schaut euch das Video `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=zEPXyqj7pEA"
      }}><span lang="en">{`„The CSS Cascade, a deep dive“`}</span></a>{`
von Bramus Van Damme an, um eine detaillierte Einführung in die CSS-Kaskade zu erhalten.`}</p>
    <h2>{`Die `}<InlineCode mdxType="InlineCode">{`@layer`}</InlineCode>{` Deklaration`}</h2>
    <p>{`Grundsätzlich deklarieren wir einen neuen Layer inklusive zugehöriger CSS-Regeln mit
der `}<InlineCode mdxType="InlineCode">{`@layer`}</InlineCode>{` Deklaration:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@layer my-layer {
    // CSS rules
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ihr könnt auch einen benannten Cascade Layer definieren, ohne diesem CSS-Regeln zuzuweisen. Auf diese Weise könnt ihr
die Hierarchie eurer Layer bestimmen. Als Best Practice wird empfohlen, zunächst die Hierarchie der Layer
mit `}<InlineCode mdxType="InlineCode">{`@layer`}</InlineCode>{` zu definieren und danach erst die konkreten CSS-Regeln den Layern hinzuzufügen: `}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@layer my-layer-1, my-layer-2;

@layer my-layer-1 {
    // CSS rules
}

@layer my-layer-2 {
    // CSS rules
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Alle Styles, die nicht innerhalb eines Layers deklariert wurden, werden automatisch als Teil eines anonymen Layers
erfasst. Dieser anonyme Layer hat eine höhere Priorität als die explizit definierten Layer. Das bedeutet, dass alle
CSS-Regeln, die außerhalb von Layern definiert wurden, Vorrang vor den Styles innerhalb von Layern haben.`}</p>
    <p>{`Ich gehe hier nicht auf alle Details der Cascade Layer ein. Bitte lest dazu den großartigen Artikel
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/css-cascade-layers/"
      }}><span lang="en">{`„A Complete Guide to CSS Cascade Layers“`}</span></a>{`.`}</p>
    <h2>{`Demo-Applikation mit Angular und SCSS`}</h2>
    <p>{`Ich habe eine Applikation mit `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular 14`}</a>{` und `}<a parentName="p" {...{
        "href": "https://leafletjs.com/"
      }}>{`Leaflet`}</a>{` erstellt, welche
eine interaktive Karte anzeigt. Im Projekt verwende ich SCSS, da es tolle Features wie Placeholders und Mixins bietet.
Seht euch `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/cascade-layers-angular-demo"
      }}>{`meinen Quellcode hier an`}</a>{`.`}</p>
    <p>{`Mein Ziel ist einfach: Ich will sicherstellen, dass mein Styling für individuelle Komponenten immer angewandt wird.
Es sollte Vorrang vor meinem Styling-Reset sowie den CSS-Regeln von Drittanbieter-Bibliotheken haben. Zu diesem Zweck
habe ich mehrere Layer in der zentralen CSS-Datei `}<InlineCode mdxType="InlineCode">{`styles.scss`}</InlineCode>{` definiert:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@use "sass:meta";

@layer reset, thirdparty, overrides;

@layer reset {
    @include meta.load-css("layers/reset/normalize");
}

@layer thirdparty {
    @include meta.load-css("node_modules/leaflet/dist/leaflet.css");
}

@layer overrides {
    @include meta.load-css("layers/overrides/leaflet");
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ich verwende die SASS-Funktion `}<InlineCode mdxType="InlineCode">{`load-css`}</InlineCode>{`, um das CSS aus anderen SCSS-Dateien zu laden und es
als Teil eines Cascade Layers zu definieren. Das Reset für User Agent Styles gebe ich in den Layer mit der niedrigsten
Priorität. Darauf folgt ein Layer für das Standard-Styling der Leaflet-Karte und ein Layer für das globale Überschreiben
von Styles.`}</p>
    <p>{`Ich definiere keinen Layer für die Styles von einzelnen Komponenten, welche in eigenen SCSS-Dateien deklariert werden.
Diese CSS-Regeln werden daher automatisch in einem anonymen Layer gesammelt und erhalten die höchste Priorität. Sehen
wir uns ein konkretes Beispiel an.`}</p>
    <h2>{`Styling eines Karten-Popups`}</h2>
    <p>{`In meiner Demo-App liste ich meine 5 Lieblingsplätze in Wien auf. Dieses werden auf einer interaktiven
Leaflet-Karte markiert. Beim Klick auf einen Marker öffnet sich ein Popup. Das
HTML-Template `}<InlineCode mdxType="InlineCode">{`fav-place-popup.component.html`}</InlineCode>{` des Popups sieht so aus:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<h2>{{data.name}}</h2>
<p *ngFor="let desc of data.description">{{desc}}</p>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In `}<InlineCode mdxType="InlineCode">{`fav-place-popup.component.scss`}</InlineCode>{` habe ich folgende Styles definiert:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`p {
    font-size: 0.8rem;
    margin: 0.5em 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Darüber hinaus definiert die Leaflet-Bibliothek folgendes Styling für Textabsätze innerhalb von Karten-Popups:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.leaflet-popup-content p {
    margin: 18px 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Dank meiner etablierten Layer-Struktur muss ich keine Angst davor haben, dass Leaflets Styling
für `}<InlineCode mdxType="InlineCode">{`.leaflet-popup-content p`}</InlineCode>{` mit meinen Komponenten-Styles für `}<InlineCode mdxType="InlineCode">{`p`}</InlineCode>{`
in Konflikt geraten könnten. Gleichzeitig sorgt Angulars `}<a parentName="p" {...{
        "href": "https://angular.io/guide/view-encapsulation"
      }}>{`View Encapsulation`}</a>{`
dafür, dass meine Komponenten-Styles nicht auf alle Textabsätze in meiner Applikation angewandt werden. Genial!`}</p>
    <h2>{`Fazit`}</h2>
    <p>{`Ich liebe Cascade Layer! Sie sind ein mächtiges Werkzeug zur Ordnung von CSS-Styles und helfen, nervige Konflikte
aufgrund der Spezifität oder Reihenfolge der Deklaration zu vermeiden.`}</p>
    <p>{`Ein Problem konnte ich noch nicht lösen: Manche Angular-Bibliotheken (z.B. `}<a parentName="p" {...{
        "href": "https://material.angular.io/"
      }}>{`Angular Material`}</a>{`)
fügen automatisch Styles im `}<InlineCode mdxType="InlineCode">{`head`}</InlineCode>{` Tag des HTML-Dokuments ein. Bisher habe ich leider keinen
Weg gefunden, um diese Styles innerhalb eines Layers meiner Wahl zu deklarieren. Ich hoffe, dass es in Zukunft eine
Lösung für dieses Problem geben wird.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      